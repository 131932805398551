import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

//import component
import TicketReply from "./TicketReply"

//import config
import config from "../../config"
//import lib
import { momentFormat } from '../../lib/dateTimeHelper';
import isEmpty from '../../lib/isEmpty';


const ChatList = (props) => {
    //props
    const { ticketRecord, receiver, sender } = props;

    // state
    const [ticketData, setTicketData] = useState({});

    // redux-state
    const accountData = useSelector((state) => state.account);
    const { profile } = accountData;

    // function
    const replyChatFun = (replyMsg) => {
        setTicketData({ ...ticketData, ...{ reply: replyMsg } });
    };

    const closeTicketFun = (status) => {
        setTicketData({ ...ticketData, ...{ status: status } });
    };

    useEffect(() => {
        if (ticketRecord) {
            setTicketData(ticketRecord);
        }
    }, []);

    return (
        <>
            {/* <div
                id={"collapseOne" + eventKey}
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#faq_accordian"
            > */}
            <span>
                Creates on: {momentFormat(ticketData.createdAt)}
            </span>
            <div className="accordion-body">
                <div className="ticket_msg_card_wrapper">
                    {
                        !isEmpty(ticketData) && ticketData.reply && ticketData.reply.length > 0 && ticketData.reply.map((el, i) => {
                            if (el.senderId == sender._id) {
                                return (
                                    <div className="ticket_msg_card ticket_msg_card_user">
                                        <div className="ticket_msg_card_header">
                                            {profile ? (
                                                <img
                                                    src={profile}
                                                    className="img-fluid"
                                                    alt="Profile"
                                                    width={50}
                                                />
                                            ) : (
                                                <i className="bi bi-person-fill"></i>
                                            )}
                                            <div>
                                                <h4>{sender.userName}</h4>
                                                <p>{momentFormat(el.createdAt)}</p>
                                            </div>
                                        </div>
                                        <div className="ticket_msg_card_desc">
                                            <p>
                                                {el.message}
                                            </p>
                                            {el.image && (
                                                <>
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            config.API_URL +
                                                            "/images/support/" +
                                                            el.image
                                                        }
                                                    >
                                                        View File
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="ticket_msg_card ticket_msg_card_admin">
                                        <div className="ticket_msg_card_header">
                                            <i className="bi bi-headset"></i>
                                            <div>
                                                <h4>Support Team</h4>
                                                <p>{momentFormat(el.createdAt)}</p>
                                            </div>
                                        </div>
                                        <div className="ticket_msg_card_desc">
                                            <p>{el.message}</p>
                                            {el.image && (
                                                <>
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            config.API_URL +
                                                            "/images/support/" +
                                                            el.image
                                                        }
                                                    >
                                                        View File
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                    {/* </div> */}
                    {ticketRecord.status == "open" && (
                        <TicketReply receiverId={receiver._id}
                            ticketId={ticketRecord._id}
                            replyChatFun={replyChatFun}
                            closeTicketFun={closeTicketFun} />
                    )
                    }
                </div>
            </div>
        </>
    )
}


export default ChatList;